import { createRouter, createWebHistory } from 'vue-router'

import Layout from '../components/Layout'
import PayMent from '../components/PayMent'

const routes = [
        {
            path:'/',
            component: Layout,
            meta:{
                title:'会员申请'
            }
        },
        {
            path:'/pay',
            component:PayMent,
            meta:{
                title:'链接续费'
            }
        }
]

// 创建Router对象
export default createRouter({
    history: createWebHistory(),
    routes
})