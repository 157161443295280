import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus'; 
import 'element-plus/theme-chalk/index.css';
import 'element-plus/theme-chalk/display.css';
import router from './router/index'
import VueWechatTitle from 'vue-wechat-title'
// import axios from 'axios'
// import Vue from 'vue'
// import VueAxios from 'vue-axios'

// Vue.prototype.$axios = axios;
// axios.defaults.baseURL = '/api'


createApp(App).use(ElementPlus).use(router).use(VueWechatTitle).mount('#app');
// createApp(App).use(VueAxios,axios).use(ElementPlus).use(router).use(VueWechatTitle).mount('#app');

