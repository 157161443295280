<template>
  <router-view v-wechat-title="$route.meta.title"/>
</template>

<script>
// import Layout from './components/Layout'

export default {
  name: 'App',
  components: {
    // Layout
  }
}
</script>

<style>
</style>
