<template>
  <div v-show="payConfig" style="width:100%;height: 100%;">
    <div style="width: 100%;height: auto; ">
      <el-form class="el-form_ip" :model="form" label-width="auto" size="large" :rules="rules" ref="form" 
      style="padding-top:5%;padding-left: 3%;padding-bottom: 5%;border-radius: 12px;">
        <!-- 表单头部 -->
        <el-row style="margin-top:-7%">
          <el-col :span="24">
            <!-- <div style="width: 60%;height: auto;padding-left: 35%;">
              <img src="../assets/ip_form_head.jpg" style="max-width: 60%;height: auto;" />
            </div> -->
            <!-- 即将为以下企业进行会员续费！ -->
          </el-col>
        </el-row>

        <!-- 联系人信息 -->
        <el-row>
          <p style="font-size: large;font-weight: bold;">续费类型</p>
        </el-row>
        <el-form-item>
          <div>
            <el-radio-group v-model="form.radio" size="large" >
              <el-radio-button label="会员单位" />
              <el-radio-button label="理事单位" />
              <el-radio-button label="副会长单位"/>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-row>
          <p style="font-size: large;font-weight: bold;">基本信息</p>
        </el-row>
        <el-row justify="start">
          <el-col :span="10">
            <p style="opacity: 0.7;"> 公司名称</p>
          </el-col>
          <el-col :span="14">
          </el-col>
          <el-col :span="22">
            <el-form-item  style=" " prop="mobile">
              <el-input v-model="form.corporate_name" :span="11" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <p style="opacity: 0.7;"> 姓名</p>
          </el-col>
          <el-col :span="14">
          </el-col>
          <el-col :span="22">
            <el-form-item  style=" " prop="name">
              <el-input v-model="form.name" :span="11" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <p style="opacity: 0.7;"> 手机</p>
          </el-col>
          <el-col :span="14">
          </el-col>
          <el-col :span="22">
            <el-form-item  style=" " prop="mobile">
              <el-input v-model="form.mobile" :span="11" :disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row justify="start">
          
        </el-row> -->
        <!-- <el-row justify="start">
          <el-col :span="10">
            <p style="opacity: 0.7;"> 姓名</p>
          </el-col>
        </el-row>
        <el-row justify="start">
          <el-col :span="22">
            <el-form-item  style=" " prop="name">
              <el-input v-model="form.name" :span="11" :disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row justify="start">
          <el-col :span="10">
            <p style="opacity: 0.7;"> 手机</p>
          </el-col>
        </el-row>
        <el-row justify="start">
          <el-col :span="22">
            <el-form-item  style=" " prop="mobile">
              <el-input v-model="form.mobile" :span="11" :disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row> -->

        <!-- 公司信息 -->
        <!-- <el-row>
          <p style="font-size: large;font-weight: bold;">公司信息</p>
        </el-row> -->
      </el-form>
      <el-row justify="start">
        <el-col :span="24">
          <p style="opacity: 0.7;"><span class="required" style="font-size:12px;">*请仔细确认会员信息是否正确</span> </p>
        </el-col>
      </el-row>
      <el-row justify="start">
        <el-col :span="24">
          <p style="opacity: 0.7;"><span class="required" style="font-size:12px;"> </span> </p>
        </el-col>
      </el-row>
      <el-row justify="start">
        <el-col :span="24">
          <p style="opacity: 0.7;"><span class="required" style="font-size:12px;"> </span> </p>
        </el-col>
      </el-row>
      <el-row justify="start">
        <el-col :span="24">
          <p style="opacity: 0.7;"><span class="required" style="font-size:12px;"> </span> </p>
        </el-col>
      </el-row>
      <!-- ip按钮 -->
      <el-row style="">
        <el-col :span="2"></el-col>
        <el-col :span="20">
          <!-- <div style="width: 100%;height:50px;">
            <button @click="onSubmit" :disabled="application_btn" style="font-size:15px;width: 40%;height:50px;line-height: 50px; background-color: black;color: white; border-radius: 4px;  margin:0 30%;text-align: center;">提交申请</button>
          </div> -->
          <div class="sub_btn" style="width: 100%;height:50px;">
            
            <button type="button"  @click="confirm" :disabled="application_btn" style="font-size:15px;width: 40%;height:50px;line-height: 50px; background-color: black;color: white; border-radius: 4px;  margin:0 5%;text-align: center;">确认</button>
            <button type="button" @click="cancel" :disabled="application_btn" style="font-size:15px;width: 40%;height:50px;line-height: 50px; background-color: white;color: black; border-radius: 4px;  margin:0 5%;text-align: center;">取消</button>
          </div>
        </el-col>
        <el-col :span="2"></el-col>
      </el-row>
    </div>
  </div>

</template>
<script>

// let wx = window.wx;

import wx from 'weixin-jsapi'
import axios from 'axios';
import sha1 from 'js-sha1';
import { useRoute } from 'vue-router';
export default {
    name:'Layout',
    data () {
        return {
          //表单
          form:{
            name: '',//姓名
            position:'',//职位
            radio:'会员单位',//入会类型
            mobile:'',//手机
            email:'',//邮箱
            corporate_name:'',//公司名称
            company_address:'',//公司地址
            msg:'',//留言
          },
          //校验规则
          rules: {
            name: [
              { required: true, message: '姓名不能为空', trigger: 'blur' },
              { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
            ],
            mobile:[
              { validator:this.validatorMobile, trigger: 'blur'},
            ],
            corporate_name:[
              { required: true, message: '公司名称不能为空', trigger: 'blur' },
            ]
          },
          //按钮禁用
          application_btn:false,
          //提交后弹框
          dialogVisible:false,
          //响应式弹框宽度
          dialogWidth:0,
          //抽屉显示
          drawer:false,
          //抽屉方向
          direction:'ltr',
          //表单提交loading...
          loading:false,
          //选择加载loading
          loadingSelect:false,
          //判断申请结果状态
          subStatus:false,
          //申请失败的错误信息
          subErrorMsg:'',
          //微信js-api-token
          token:"72_INA9C5VaAupGN-asXNhKhAv1JdOa2NEUjLPxBDHBHTSiFbbJj1VRVUnNvgpXnIZP6jMGZz4aZPWt2wETfDjvCgoO70XifwbgypUDNFefQl8bh01EzDcDPwEP8LAQQIaADAFFX",
          //微信js-api-ticket
          ticket:'O3SMpm8bG7kJnF36aXbe8yyqrylnh2AduvKyj3fiRhnNAOF-1uZGCE_90ju126CW8AvjGI21gtu4OQqh9UTugQ',
          //企业id
          corpid: 'wwc61024303f3b5649',
          //企业secret
          secret: 'gUu-gYwmedX3EqzE2gHv_uSz1y5SVa0wgXYB6ZA8-wk',
          //会员人员资料ID
          accountId:'',
          //跟进人
          follow_up_person:'',
          //负责人
          head:'',
          //推荐人
          reference:'',
          //来源
          source:'',
          //行记录ID
          rowId:'',
          //二维码id
          config_id:'',
          //微信授权码
          code:'',
          //微信支付的手机号码
          state:'',
          //选择列表
          options:[],
          //openId
          openid:'',
          //可续费
          renewable:false,
          //续费弹框控制
          renewDialog:false,
          //续费结束弹框控制
          renewEndDialog:false,
          //手机号固定
          iphone_number:'',
          //支付链接
          payUrl:'',
          //支付确认
          payConfig:true,
        }
    },

    methods:{
      //确认续费
      confirm(){
        // this.renewDialog = false;
        this.application_btn = true;
        this.getPayUrlChatId(this.openid,this.iphone_number);
      },
      
      //选择项
      remoteMethod(queryString){
        var that = this;
        if (queryString !== '') {
          that.loadingSelect = true
          setTimeout(() => {
            that.loadingSelect = false
            axios.get('/tianyancha/services/open/search/2.0?word='+queryString+'&pageSize=20&pageNum=1',{
              headers: {
                'Authorization': '08d341f3-c960-44a0-9342-e614c68c3c1b'
              }
            }).then(async function(data){
              if(data.data.error_code==0){
                that.options = [];
                var arr = data.data.result.items;
                arr.forEach(function(e){
                  var obj = {value: e.name,label:e.name};
                  that.options.push(obj);
                });
              }
            });
          }, 200)
        } else {
          that.options = []
        }
      },
      //选择公司名称项
      handleSelect(item){
        this.corporate_name = item.value;
      },
      //导航栏显示控制
      head_ip_click(){
        this.drawer = !this.drawer;
      },
      //表单重置
      resetForm() {
        this.$refs['form'].resetFields();
      },
      //取消（再填写一份）
      cancel(){
        // this.resetForm();
        // this.dialogClose();
        window.location.href = 'https://application.sgia.org.cn';
      },
      //设置宽度
      setDialogWidth() {
        var val = document.body.clientWidth
        const def = 800 // 默认宽度
        if (val < def) {
          this.dialogWidth = '80%'
        } else {
          this.dialogWidth = 40 + '%'
        }
      },
      //获取时间戳
      getTimestamp() {
        return Date.parse(new Date());
      },
      //随机字符串
      randomString(len){
        len = len || 32
        var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
        var maxPos = $chars.length
        var pwd = ''
        for (var i = 0; i < len; i++) {
          pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
        }
        return pwd;
      },
      //获取签名
      getSignature(ticket,noncestr,timestamp,url) {
        let jsapi_ticket = `jsapi_ticket=${ticket}&noncestr=${noncestr}&timestamp=${timestamp}&url=${url}`
        return sha1(jsapi_ticket);
      },
      //获取accessToken
      accessToken(){
        var that = this;
        axios.get('/wxapi/cgi-bin/token?grant_type=client_credential&appid=wxd319ed66e5318016&secret=f08bbe56e70cf7116ea198b8ca248797').then(function(res){
          that.token = res.data.access_token;
          return res.data.access_token;
        });
      },
      //获取ticket
      getTicket(){
        var that = this;
        axios.get('/wxapi/cgi-bin/ticket/getticket?access_token='+that.token+'&type=jsapi').then(function(res){
          that.token = res.data.access_token;
          if(res.data.errcode==40001){
            that.accessToken();
            axios.get('/wxapi/cgi-bin/ticket/getticket?access_token='+that.token+'&type=jsapi').then(function(res){
              that.ticket = res.data.ticket;
              return res.data.ticket;
            });
          }
          return res.data.ticket;
        });
      },
      //获取续费链接预支付会话id
      async getPayUrlChatId(openid,iphone_number){
        var that = this;
        axios.get('/pay-api/payUrl/'+openid+'/'+iphone_number+'/'+that.form.radio).then(async function(res){
          var a = res['data'];
          var b = a['data'];
          await that.wxPayUrlCallback(b);
        }).catch(error=>{
          alert('抱歉，系统发生错误，请截图联系工作人员，错误信息：'+error);
        })
      },
      //获取预支付会话id
      getPayChatId(openid,mobile){
        var that = this;
        axios.get('/pay-api/getToken/'+openid+'/'+mobile).then(async function(res){
          var a = res['data'];
          var b = a['data'];
          await that.wxCallback(b);
        }).catch(error=>{
          alert('抱歉，系统发生错误，请截图联系工作人员，错误信息：'+error);
        })
      },
      
      //检测微信插件是否准备完成
      async wxPayUrlCallback(options){
        let that = this;
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', that.onPayUrlBridgeReady(options), false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', that.onPayUrlBridgeReady(options));
            document.attachEvent('onWeixinJSBridgeReady', that.onPayUrlBridgeReady(options));
          }
        } else {
          await that.onPayUrlBridgeReady(options);
        }
      },
      async onPayUrlBridgeReady(options){
        let that = this;
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
          "appId": options['appId'],     //公众号ID，由商户传入     
          "timeStamp": options['timeStamp'],     //时间戳，自1970年以来的秒数     
          "nonceStr": options['nonceStr'],      //随机串     
          "package": options['package'],
          "signType": options['RSA'],     //微信签名方式：     
          "paySign": options['paySign']
        },
        async function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付成功');
              that.application_btn = false;
              //修改订单状态
              await that.updateOrderState("支付成功");
          }
          if (res.err_msg == "get_brand_wcpay_request:cancel") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付取消');
              that.application_btn = false;
              //修改订单状态
              await that.updateOrderState("未支付");
          }
          if (res.err_msg == "get_brand_wcpay_request:fail") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付失败');
              that.application_btn = false;
              //修改订单状态
              await that.updateOrderState("未支付");
          }
        });
      },
      //检测微信插件是否准备完成
      async wxCallback(options){
        let that = this;
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(options), false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(options));
            document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(options));
          }
        } else {
          await that.onBridgeReady(options);
        }
      },
      ///入会支付通知
      async onBridgeReady(options){
        let that = this;
        that.loading = false;
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
          "appId": options['appId'],     //公众号ID，由商户传入     
          "timeStamp": options['timeStamp'],     //时间戳，自1970年以来的秒数     
          "nonceStr": options['nonceStr'],      //随机串     
          "package": options['package'],
          "signType": options['RSA'],     //微信签名方式：     
          "paySign": options['paySign']
        },
        async function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付成功');
              //修改订单状态
              await that.updateOrderState("支付成功");
              that.loading = false;
              that.application_btn = false;
          }
          if (res.err_msg == "get_brand_wcpay_request:cancel") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付取消');
              
              //修改订单状态
              await that.updateOrderState("未支付");
              that.loading = false;
              that.application_btn = false;
          }
          if (res.err_msg == "get_brand_wcpay_request:fail") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付失败');
              //修改订单状态
              await that.updateOrderState("未支付");
              that.loading = false;
              that.application_btn = false;
          }
        });
      },
      //检测微信插件是否准备完成
      async wxRenewCallback(options){
        let that = this;
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', that.renewOnBridgeReady(options), false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', that.renewOnBridgeReady(options));
            document.attachEvent('onWeixinJSBridgeReady', that.renewOnBridgeReady(options));
          }
        } else {
          await that.renewOnBridgeReady(options);
        }
      },
      ///支付通知
      async renewOnBridgeReady(options){
        let that = this;
        that.loading = false;
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
          "appId": options['appId'],     //公众号ID，由商户传入     
          "timeStamp": options['timeStamp'],     //时间戳，自1970年以来的秒数     
          "nonceStr": options['nonceStr'],      //随机串     
          "package": options['package'],
          "signType": options['RSA'],     //微信签名方式：     
          "paySign": options['paySign']
        },
        async function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付成功');
              //修改订单状态
              await that.updateOrderState("支付成功");
              that.loading = false;
              that.application_btn = false;
          }
          if (res.err_msg == "get_brand_wcpay_request:cancel") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付取消');
              
              //修改订单状态
              await that.updateOrderState("未支付");
              that.loading = false;
              that.application_btn = false;
          }
          if (res.err_msg == "get_brand_wcpay_request:fail") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付失败');
              //修改订单状态
              await that.updateOrderState("未支付");
              that.loading = false;
              that.application_btn = false;
          }
        });
      },
      //初始化微信插件
      initWxConfig(){
        let that = this;
        that.getTicket();
        var nonceStr = that.randomString(32);
        var timestamp = that.getTimestamp();
        var url = 'https://application.sgia.org.cn/';
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxd319ed66e5318016', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: that.getSignature(that.ticket,nonceStr,timestamp,url),// 必填，签名
          jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function(){
          wx.checkJsApi({
            jsApiList: ['chooseWXPay'],
            success:function(res){
            },
            fail:function(res){
            }
          })
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        });
        wx.error(function(res){
          alert('微信插件加载失败：'+res);
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });

      },
      // 调用支付
      wxpayment(){
        var mobile = this.form.mobile;
        // if(typeof(this.code) == "undefined" || this.code == '' || this.code == null){
        var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd319ed66e5318016&redirect_uri="+encodeURI("https://application.sgia.org.cn")+"&response_type=code&scope=snsapi_base&state="+mobile+"#wechat_redirect";
        window.location.href = url;
        // }
      },
      //修改订单状态
      async getOrderRowId(result){
        var that = this;
        var mobile = that.iphone_number;
        //根据手机号获取订单信息，把当前的支付通知返回给明道云
        await axios.post('/mdy/v2/open/worksheet/getFilterRows',
        {
          "appKey": "01f242851a8cd3f5",
          "sign": "MmY5MDgxNTc1YTQ0YjA4NjgwZTJhODQzZGVkYWQ3ZThhZGE2NjdkMjVkNzU1OTgzMGFjMDFiMzllNjRmODI1YQ==",
          "worksheetId": "dingdan",
          "viewId": "",
          "pageSize": 50,
          "pageIndex": 1,
          "filters": [
            {
              "controlId": "6506851d533dc247c621d529",
              "dataType": 3,
              "spliceType": 1,
              "filterType": 2,
              "value": mobile
            }
          ]
        },{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(async function(res){
          //明道云新增后的行记录id
          var a = await res.data.data.rows[0];
          var rowId =await a['rowid'];
          await that.updateOderStateByRowId(rowId,result);
          alert('即将跳转深服协官网~');
          setTimeout(()=>{
            window.location.href = 'https://application.sgia.org.cn';
          },3000);
        }).catch(function(error){
          alert('修改订单状态错误:'+error);
        });
      },
      //修改订单状态
      async updateOrderState(result){
        var that = this;
        await that.getOrderRowId(result);
      },
      //根据行id修改订单状态
      async updateOderStateByRowId(rowId,result){
        let that = this;
        await axios.post('/mdy/v2/open/worksheet/editRow',
        {
          "appKey": "01f242851a8cd3f5",
          "sign": "MmY5MDgxNTc1YTQ0YjA4NjgwZTJhODQzZGVkYWQ3ZThhZGE2NjdkMjVkNzU1OTgzMGFjMDFiMzllNjRmODI1YQ==",
          "worksheetId": "dingdan",
          "rowId":rowId,
          "controls": [
            {
              "controlId": "64fe7bbc8255b422ee951939",
              "value": result,
              "valueType": 1
            },{
              "controlId": "65292460c560948027d12424",
              "value": that.rowId,
            },{
              "controlId": "657feed2bf6617bba9bd5921",
              "value": that.state,
            }
          ],
          "triggerWorkflow": true
        },{
          headers: {
            'Content-Type': 'application/json'
          }
        }).catch(function(error){
          alert(error);
        });
      },
      //根据rowId获取支付链接的信息
      async getPayInfoes(){
        var that = this;
        const appKey = '01f242851a8cd3f5';
        const sign = 'MmY5MDgxNTc1YTQ0YjA4NjgwZTJhODQzZGVkYWQ3ZThhZGE2NjdkMjVkNzU1OTgzMGFjMDFiMzllNjRmODI1YQ==';
        const worksheetId = '62998aa5e9319b9e521ee518';
        var rowId = that.state;
        // var rowId = 'dc6dfadc-acd2-49dd-89f0-1463a128fe0e';
        const getSystemControl = false;
        axios.get(`/mdy/v2/open/worksheet/getRowById?appKey=${appKey}&sign=${sign}&worksheetId=${worksheetId}&rowId=${rowId}&getSystemControl=${getSystemControl}`,
        {},{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(function(res){
          //公司名
          that.form.corporate_name = res.data.data['gsmc'];
          //姓名
          that.form.name = res.data.data['xingming'];
          //手机
          that.iphone_number = that.form.mobile = res.data.data['shouji'].slice(3);
          //入会类型
          that.form.radio = res.data.data['rhlx'];
          //状态
          var state = res.data.data['zhuangtai'];
          if(state == '新' || state == '待跟进' || state == '跟进中' || state =='入会申请中' || state == '待付款' || state == '付款审批中' || state == '不合格'){
            // alert(state);
            that.application_btn = true;
            alert('您的企业目前还不能进行续费,即将跳转至首页！');
            setTimeout(()=>{
              window.location.href = 'https://application.sgia.org.cn';
            },3000);
          }
        }).catch(function(error){
          alert(error);
        });
      },
      
      //根据code获取openId
      async getOpenIdByCode(){
        let that = this;
        axios.get('/wxapi/sns/oauth2/access_token?appid=wxd319ed66e5318016&secret=f08bbe56e70cf7116ea198b8ca248797&code='+that.code+'&grant_type=authorization_code')
        .then(async res=>{
          that.openid = await res.data.openid;
          await this.getPayInfoes();
        }).catch(error => {
          alert('抱歉，系统错误：'+error+';请截图联系工作人员处理！');
        });
      },
      //查询线索中是否存在该openId
      async queryIsHavingOpenId(openid){
        let that = this;
        axios.post('/mdy/v2/open/worksheet/getFilterRows',
        {
          "appKey": "01f242851a8cd3f5",
          "sign": "MmY5MDgxNTc1YTQ0YjA4NjgwZTJhODQzZGVkYWQ3ZThhZGE2NjdkMjVkNzU1OTgzMGFjMDFiMzllNjRmODI1YQ==",
          "worksheetId": "6528f1fce85e22627f91d638",
          "viewId": "",
          "pageSize": 50,
          "pageIndex": 1,
          "sortId":'ctime',
          "isAsc":false,
          "filters": [
            {
              "controlId": "6567076ec252a8286bdf1f5b",
              "dataType": 2,
              "spliceType": 1,
              "filterType": 2,
              "value": openid
            },{
              "controlId": "62998aa5e9319b9e521ee519",
              "dataType": 11,
              "spliceType": 1,
              "filterType": 2,
              "value": '新线索'
            }
          ]
        },{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(async function(res){
          var res_list = await res.data.data.rows;
          if(res_list.length>0){
            var obj = res_list[0];
            await that.queryDqTimeByOpenId(obj['652dee128c71301c58a0c39d']);
            //公司名
            that.form.corporate_name = obj['652dee128c71301c58a0c39d'];
            //姓名
            that.form.name = obj['xingming'];
            //职位
            that.form.position = obj['65308b2ab2073276dba35457'];
            //手机
            that.iphone_number = that.form.mobile = obj['shouji'].slice(3);
            //email
            that.form.email = obj['65308b2ab2073276dba35458'];
            //公司地址
            that.form.company_address = obj['65308b3e187a94264865a0a6'];
            //留言
            that.form.msg = obj['64ddec1fed4b09e16066ba21'];
            //申请单位
            that.form.radio = obj['rhlx'];
            //rowId
            that.rowId = obj['rowid'];
            //开启续费按钮
            // var status = obj['zhuangtai'];
            // if(status == '服务中' || status == '已付款' || status == '即将到期' || status == '已过期'){
            //   that.renewable = true;
            // }
          }
        }).catch(function(error){
          alert(error);
        });
      },
      //根据openid查询是否可续费
      async queryDqTimeByOpenId(gsmc){
        let that = this;
        axios.post('/mdy/v2/open/worksheet/getFilterRows',
        {
          "appKey": "01f242851a8cd3f5",
          "sign": "MmY5MDgxNTc1YTQ0YjA4NjgwZTJhODQzZGVkYWQ3ZThhZGE2NjdkMjVkNzU1OTgzMGFjMDFiMzllNjRmODI1YQ==",
          "worksheetId": "62998aa5e9319b9e521ee518",
          "viewId": "",
          "pageSize": 50,
          "pageIndex": 1,
          "sortId":"ctime",
          "isAsc":false,
          "filters": [
            {
              "controlId": "62998aa5e9319b9e521ee522",
              "dataType": 51,
              "spliceType": 1,
              "filterType": 2,
              "value": gsmc
            }
          ]
        },{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(async function(res){
          var res_list = await res.data.data.rows;
          if(res_list.length>0){
            var obj = res_list[0];
            //当前状态
            var status = obj['zhuangtai'];
            if(status == '服务中' || status == '已付款' || status == '即将到期' || status == '已过期'){
              that.renewable = true;
            }
          }
        }).catch(function(error){
          alert(error);
        });
      }
    },

    watch:{

    },

    created(){
      this.setDialogWidth();
    },

    async mounted(){
      
      // alert('123123');
      

      //获取路由参数
      const route = useRoute();
      this.code = route.query.code;
      this.state = route.query.state;
      this.accountId = route.query.accountId;
      this.source = route.query.source;
      this.payUrl = route.query.payUrl;

      if(typeof(this.code) == "undefined"){
        //未授权
        var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd319ed66e5318016&redirect_uri="+encodeURI("https://application.sgia.org.cn/pay")+"?response_type=code&scope=snsapi_base&state="+this.payUrl+"#wechat_redirect";
        window.location.href = url;
      }else{
       
        this.getOpenIdByCode();
      }
      //初始化微信插件
      this.initWxConfig();
      window.onresize = () => {
        return (() => {
          this.setDialogWidth()
        })()
      };
    }
}
</script>

<style scoped>
  .required{
    color: red;
  }

  .hidden-xs-only > div > ul > li{
    display: inline-block;
    height: auto;
    line-height: auto;
    margin-left:4%;
    font-size: large;
    
  }

  .hidden-xs-only > div > ul > li:hover{
    cursor: pointer;
  }

  .action_pc{
    
    font-size: larger;
    font-weight: bold;
    font: #000000;
  }

  .ip_ul > li{
    width: 100%;
    height: auto;
    line-height: auto;
    font-size: 14px;
    font: #000000;
    opacity: 0.95;
    margin-top: 15%;
  }
  
  .ip_ul > li:hover{
    cursor: pointer;
  }

  .ip_ul > .action{
    font-size: 14px;
    font-weight: 600;
    opacity: 1;
  }

  .sub_span:hover{
    cursor: pointer;
    color: rgb(38, 125, 230) ;
    box-shadow: 0px 1px 3px 3px rgb(38, 125, 230);
  }

  .ip_sub_btn:hover{
    cursor: pointer;
  }

  .head_ip:hover{
    cursor: pointer;
  }

  .el-form_ip{
    box-shadow: 0px 2px 3px 3px #f8f9fb;
  }
</style>