<template>
  <div style="width:100%;height: 100%;">
    <!-- head  -->
    <el-row :gutter="10" style="height: auto;width: 100%; z-index: 99; background-color:#FFFFFF; box-shadow: 0px 0px 3px 1px #F1F1F1;position:fixed;top: 0px; ">
      <!-- pc -->
      
      <el-col class="hidden-xs-only"  :sm="5" :md="5" :lg="5" :xl="5" style="height: 90px;">
        <div style="height: 100%;width: 100%;">
          <div style="height:60%;width:100%;position: relative;left:1%;top:20%;">
            <img src="../assets/logo.jpg"   style="position:absolute;left: 12%;top:0%;max-width: 63%;height: auto;"/>
          </div>
        </div>
      </el-col>
      <el-col class="hidden-xs-only" :sm="19" :md="19" :lg="19" :xl="19" style="height: 90px;">
        <div style="height:60%;width:100%;position: relative;left:5%;top:10%;">
          <ul style="list-style: none;width:100%;height:100%;position: absolute;top: 0%;left: 0;">
            <li @click="turnToIndex">首页</li>
            <li @click="turnToSzz">深圳时装周</li>
            <li @click="turnToXhjs">协会介绍</li>
            <li @click="turnToHysq" class="action_pc">会员申请</li>
            <li @click="turnToXxgk">信息公开</li>
            <li @click="turnToDjyl">党建引领</li>
            <li @click="turnToZscqbhzq">知识产权保护专区</li>
          </ul>
        </div>
      </el-col>

      <!-- ip -->
      <el-col class="hidden-sm-and-up" :span="3" style="height:44px ;">
        <div style="width: 100%;height: 100%;">
          <img @click="head_ip_click" class="head_ip" src="../assets/head_ip.png" style="max-width: 65%;margin:16% 15%"/>
        </div>
        <el-drawer
          v-model="drawer"
          :z-index="1"
          :direction="direction"
          :with-header="false"
          size="40%"
          :append-to-body="true"
          style="padding:44px 0px;"
          :lock-scroll="false"
        >
          <ul class="ip_ul" style="list-style: none;width:100%;height:70%;padding-left: 0;">
            <li @click="turnToIndex">首页</li>
            <li @click="turnToSzz">深圳时装周</li>
            <li @click="turnToXhjs">协会介绍</li>
            <li @click="turnToHysq" class="action">会员申请</li>
            <li @click="turnToXxgk">信息公开</li>
            <li @click="turnToDjyl">党建引领</li>
            <li @click="turnToZscqbhzq">知识产权保护专区</li>
          </ul>
        </el-drawer>
        
      </el-col >
      <el-col class="hidden-sm-and-up" :span="18" style="height:44px ;">
        <p style="text-align: center;line-height: 100%;">申请入会</p>
      </el-col>
      <el-col class="hidden-sm-and-up" :span="3" style="height:44px ;">
      </el-col >
    </el-row>

    <!-- body -->
    <el-row >
      <el-col class="hidden-xs-only"  :span="2" >
      </el-col>
      <!-- pc左侧banner -->
      <el-col  class="hidden-xs-only" :span="10" style="margin-top: 150px;">
        <div style="height: auto;width:100%;padding: 2% 2%;">
          <img src="../assets/banner.jpg" style="max-width:90%;height: auto;"/>
        </div>
      </el-col>
      
      <!-- pc右侧表单 -->
      <el-col class="hidden-xs-only" :span="10" style="margin-top: 150px;">
        <div style="width: 100%;height: auto; padding: 2% 2%;">
          <el-form class="el-form" v-loading="loading" :model="form" label-width="auto" size="large" :rules="rules" ref="form" >
            <!-- 表单头部 -->
            <el-row>
              <el-col :span="10">
                <div>
                  <img src="../assets/logo_form.jpg" style="max-width: 80%;height: auto;" />
                </div>
              </el-col>
            </el-row>
            <el-row>
              <p style="font-size: large;font-weight: bold;">入会类型</p>
            </el-row>

            <!-- 入会类型 -->
            <el-form-item>
              <div>
                <el-radio-group v-model="form.radio" size="large" >
                  <el-radio-button label="会员单位" />
                  <el-radio-button label="理事单位" />
                  <el-radio-button label="副会长单位"/>
                </el-radio-group>
              </div>
            </el-form-item>

            <!-- 联系人信息 -->
            <el-row>
              <p style="font-size: large;font-weight: bold;">联系人信息</p>
            </el-row>

            <el-row justify="start">
              <el-col :span="11">
                <p style="opacity: 0.7;"><span class="required">*</span> 姓名</p>
              </el-col>
              <el-col :span="2">
              </el-col>
              <el-col :span="11">
                <p style="opacity: 0.7;">职位</p>
              </el-col>
            </el-row>
            <el-row justify="start">
              <el-col :span="11">
                <el-form-item  style=" " prop="name">
                  <el-input v-model="form.name" :span="11"/>
                </el-form-item>
              </el-col>
              <el-col :span="2">
              </el-col>
              <el-col :span="11">
                <el-form-item  style=""  prop="position">
                  <el-input v-model="form.position" :span="11"/>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row justify="start">
              <el-col :span="11">
                <p style="opacity: 0.7;"><span class="required">*</span> 手机</p>
              </el-col>
              <el-col :span="2">
              </el-col>
              <el-col :span="11">
                <p style="opacity: 0.7;">Email</p>
              </el-col>
            </el-row>
            <el-row justify="start">
              <el-col :span="11">
                <el-form-item  style=" " prop="mobile">
                  <el-input v-model="form.mobile" :span="11"/>
                </el-form-item>
              </el-col>
              <el-col :span="2">
              </el-col>
              <el-col :span="11">
                <el-form-item  style="" prop="email">
                  <el-input v-model="form.email" :span="11"/>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- 公司信息 -->
            <el-row>
              <p style="font-size: large;font-weight: bold;">公司信息</p>
            </el-row>
            <el-row justify="start">
              <el-col :span="11">
                <p style="opacity: 0.7;"><span class="required">*</span> 公司名称</p>
              </el-col>
              <el-col :span="2">
              </el-col>
              <el-col :span="11">
              </el-col>
            </el-row>
            <el-row justify="start">
              <el-col :span="24">
                <el-form-item  style="width:100%" prop="corporate_name">
                  <el-select-v2
                    style="width:100%"
                    v-model="form.corporate_name"
                    :multiple="false"
                    filterable
                    remote
                    :remote-method="remoteMethod"
                    clearable
                    :options="options"
                    :loading="loadingSelect"
                    placeholder="请输入公司全称"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row justify="start">
              <el-col :span="11">
                <p style="opacity: 0.7;">公司地址</p>
              </el-col>
              <el-col :span="2">
              </el-col>
              <el-col :span="11">
              </el-col>
            </el-row>
            <el-row justify="start">
              <el-col :span="24">
                <el-form-item  style=" " prop="company_address">
                  <el-input v-model="form.company_address" type="textarea" :span="11"/>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row justify="start">
              <el-col :span="11">
                <p style="opacity: 0.7;">留言</p>
              </el-col>
              <el-col :span="2">
              </el-col>
              <el-col :span="11">
              </el-col>
            </el-row>

            <el-row justify="start">
              <el-col :span="24">
                <el-form-item  style=" " prop="msg">
                  <el-input v-model="form.msg" type="textarea" :span="11"/>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- pc按钮 -->
            <el-row style="margin-top: 5%;">
              <el-col :span="24">
                <div class="sub_btn" v-show="!renewable"  @click="onSubmit" style="width: 100%;height:60px;">
                  <button :disabled="application_btn" style="width: 30%;height:60px;line-height: 60px; background-color: black;color: white; font-size: larger;border-radius: 4px;  margin:0 40%;text-align: center;" class="sub_span">提交申请</button>
                </div>
                <div class="sub_btn" v-show="renewable"  style="width: 100%;height:60px;">
                  <button type="button"  @click="onSubmit" :disabled="application_btn" style="width: 30%;height:60px;line-height: 60px; background-color: black;color: white; font-size: larger;border-radius: 4px;  margin:0 10%;text-align: center;" class="sub_span">提交申请</button>
                  <button type="button" @click="renew" :disabled="application_btn" style="width: 30%;height:60px;line-height: 60px; background-color: black;color: white; font-size: larger;border-radius: 4px;  margin:0 10%;text-align: center;" class="sub_span">续费</button>
                </div>
              </el-col>
            </el-row>

          </el-form>
        </div>
      </el-col>
      

      <!-- ip banner -->
      <el-col class="hidden-sm-and-up" :span="1"></el-col>
      <el-col  class="hidden-sm-and-up" :span="22" style="margin-top: 64px;">
        <div style="height: auto;width:100%;">
          <img src="../assets/banner_ip.jpg" style="max-width:100%;height: auto;"/>
        </div>
      </el-col>
      <el-col class="hidden-sm-and-up" :span="1"></el-col>
      <!-- ip 表单 -->
      <el-col class="hidden-sm-and-up" :span="1"></el-col>
      <el-col class="hidden-sm-and-up" :span="22" style="margin-top: 20px;">
        <div style="width: 100%;height: auto; ">
          <el-form class="el-form_ip" :model="form" label-width="auto" size="large" :rules="rules" ref="form" 
          style="padding-top:5%;padding-left: 3%;padding-bottom: 5%;border-radius: 12px;">
            <!-- 表单头部 -->
            <el-row style="margin-top:-7%">
              <el-col :span="24">
                <div style="width: 60%;height: auto;padding-left: 35%;">
                  <img src="../assets/ip_form_head.jpg" style="max-width: 60%;height: auto;" />
                </div>
              </el-col>
            </el-row>
            <el-row>
              <p style="font-size: large;font-weight: bold;">入会类型</p>
            </el-row>

            <!-- 入会类型 -->
            <el-form-item>
              <div>
                <el-radio-group v-model="form.radio" size="large" >
                  <el-radio-button label="会员单位" />
                  <el-radio-button label="理事单位" />
                  <el-radio-button label="副会长单位"/>
                </el-radio-group>
              </div>
            </el-form-item>

            <!-- 联系人信息 -->
            <el-row>
              <p style="font-size: large;font-weight: bold;">联系人信息</p>
            </el-row>

            <el-row justify="start">
              <el-col :span="10">
                <p style="opacity: 0.7;"><span class="required">*</span> 姓名</p>
              </el-col>
              <el-col :span="3">
              </el-col>
              <el-col :span="10">
                <p style="opacity: 0.7;">职位</p>
              </el-col>
            </el-row>
            <el-row justify="start">
              <el-col :span="10">
                <el-form-item  style=" " prop="name">
                  <el-input v-model="form.name" :span="11"/>
                </el-form-item>
              </el-col>
              <el-col :span="3">
              </el-col>
              <el-col :span="10">
                <el-form-item  style="" prop="position">
                  <el-input v-model="form.position" :span="11"/>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row justify="start">
              <el-col :span="10">
                <p style="opacity: 0.7;"><span class="required">*</span> 手机</p>
              </el-col>
              <el-col :span="3">
              </el-col>
              <el-col :span="10">
                <p style="opacity: 0.7;">Email</p>
              </el-col>
            </el-row>
            <el-row justify="start">
              <el-col :span="10">
                <el-form-item  style=" " prop="mobile">
                  <el-input v-model="form.mobile" :span="11"/>
                </el-form-item>
              </el-col>
              <el-col :span="3">
              </el-col>
              <el-col :span="10">
                <el-form-item  style="" prop="email">
                  <el-input v-model="form.email" :span="11"/>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- 公司信息 -->
            <el-row>
              <p style="font-size: large;font-weight: bold;">公司信息</p>
            </el-row>
            <el-row justify="start">
              <el-col :span="10">
                <p style="opacity: 0.7;"><span class="required">*</span> 公司名称</p>
              </el-col>
              <el-col :span="3">
              </el-col>
              <el-col :span="10">
              </el-col>
            </el-row>
            <el-row justify="start">
              <el-col :span="22">
                <el-form-item  style="width:100%" prop="corporate_name">
                  <el-select-v2
                    style="width:100%"
                    v-model="form.corporate_name"
                    :multiple="false"
                    filterable
                    remote
                    :remote-method="remoteMethod"
                    clearable
                    :options="options"
                    :loading="loadingSelect"
                    placeholder="请输入公司全称"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row justify="start">
              <el-col :span="10">
                <p style="opacity: 0.7;">公司地址</p>
              </el-col>
              <el-col :span="3">
              </el-col>
              <el-col :span="10">
              </el-col>
            </el-row>
            <el-row justify="start">
              <el-col :span="22">
                <el-form-item  style=" " prop="company_address">
                  <el-input v-model="form.company_address" type="textarea" :span="11"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row justify="start">
              <el-col :span="10">
                <p style="opacity: 0.7;">留言</p>
              </el-col>
              <el-col :span="3">
              </el-col>
              <el-col :span="10">
              </el-col>
            </el-row>
            <el-row justify="start">
              <el-col :span="22">
                <el-form-item  style=" " prop="msg">
                  <el-input v-model="form.msg" type="textarea" :span="11"/>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- ip按钮 -->
            <el-row style="">
              <el-col :span="2"></el-col>
              <el-col :span="20">
                <!-- <div style="width: 100%;height:50px;">
                  <button @click="onSubmit" :disabled="application_btn" style="font-size:15px;width: 40%;height:50px;line-height: 50px; background-color: black;color: white; border-radius: 4px;  margin:0 30%;text-align: center;">提交申请</button>
                </div> -->

                <div style="width: 100%;height:50px;" v-show="!renewable"  @click="onSubmit" >
                  <button :disabled="application_btn" style="font-size:15px;width: 40%;height:50px;line-height: 50px; background-color: black;color: white; border-radius: 4px;  margin:0 30%;text-align: center;">提交申请</button>
                </div>
                <div class="sub_btn" v-show="renewable"  style="width: 100%;height:50px;">
                  <button type="button"  @click="onSubmit" :disabled="application_btn" style="font-size:15px;width: 40%;height:50px;line-height: 50px; background-color: black;color: white; border-radius: 4px;  margin:0 5%;text-align: center;">提交申请</button>
                  <button type="button" @click="renew" :disabled="application_btn" style="font-size:15px;width: 40%;height:50px;line-height: 50px; background-color: black;color: white; border-radius: 4px;  margin:0 5%;text-align: center;">续费</button>
                </div>
              </el-col>
              <el-col :span="2"></el-col>
            </el-row>
          </el-form>
        </div>
      </el-col>
      <el-col class="hidden-sm-and-up" :span="1"></el-col>
      <el-col  class="hidden-xs-only" :xs="2" :sm="2" :md="2" :lg="2" :xl="2" >
      </el-col>
    </el-row>

    <!-- foot -->
    <el-row style="margin-top: 60px">
      <el-col :span="24">
        <img src="../assets/foot.jpg" style="max-width:100% ;height: auto;"/>
      </el-col>
    </el-row>

    <!--入会申请提交后dialog-->
    <el-dialog
      v-model="dialogVisible"
      title=""
      :width="dialogWidth"
      :before-close="dialogClose"
    >
      <div v-if="subStatus">
        <el-row>
          <el-col :span="10" ></el-col>
          <el-col :span="4" >
            <img src="../assets/check.jpg" style="max-width: 80%;height: auto;"/>
          </el-col>
          <el-col :span="10"></el-col>
        </el-row>
        <el-row>
          <el-col :span="7"></el-col>
          <el-col :span="10"><p>深圳市服装行业协会入会申请</p></el-col>
          <el-col :span="7"></el-col>
        </el-row>
        <el-row>
          <el-col :span="7"></el-col>
          <el-col :span="10"><p>提交成功</p></el-col>
          <el-col :span="7"></el-col>
        </el-row>
        <el-row>
          <el-col :span="7"></el-col>
          <el-col :span="10"><p style="text-align: center;">申请成功，协会工作人员
将在3个工作日之内与您联系，感谢支持！</p></el-col>
          <el-col :span="7"></el-col>
        </el-row>
      </div>

      <div v-else>
        <el-row>
          <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10" :span="10"></el-col>
          <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" :span="4" ><img src="../assets/x-circle.jpg" style="max-width: 80%;height: auto;"/></el-col>
          <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10" :span="10"></el-col>
        </el-row>
        <el-row>
          <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7"      :span="7"></el-col>
          <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10" :span="10"><p style="text-align: center;">提交失败，您可尝试刷新页面或者联系服协工作人员</p></el-col>
          <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7"      :span="7"></el-col>
        </el-row>
        <el-row>
          <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7"      :span="7"></el-col>
          <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10" :span="10"><p style="text-align: left;font-size: xx-small;">错误信息：{{subErrorMsg}}</p></el-col>
          <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7"      :span="7"></el-col>
        </el-row>
        <el-row>
          <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7"      :span="7"></el-col>
          <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10" :span="10"><p style="text-align: left;font-size: xx-small;">联系电话：020-8397331</p></el-col>
          <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7"      :span="7"></el-col>
        </el-row>
      </div>
    
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="turnToIndex">
            返回首页
          </el-button>
          <el-button type="default" @click="cancel">
            再填一份
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 续费完成提示dialog -->
    <el-dialog
      v-model="renewEndDialog"
      title=""
      :width="dialogWidth"
      :before-close="renewEndDialogClose"
    >
      <div v-if="subStatus">
        <el-row>
          <el-col :span="10" ></el-col>
          <el-col :span="4" >
            <img src="../assets/check.jpg" style="max-width: 80%;height: auto;"/>
          </el-col>
          <el-col :span="10"></el-col>
        </el-row>
        <el-row>
          <el-col :span="7"></el-col>
          <el-col :span="10"><p>深圳市服装行业协会</p></el-col>
          <el-col :span="7"></el-col>
        </el-row>
        <el-row>
          <el-col :span="7"></el-col>
          <el-col :span="10"><p>续费成功！</p></el-col>
          <el-col :span="7"></el-col>
        </el-row>
      </div>

      <div v-else>
        <el-row>
          <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10" :span="10"></el-col>
          <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" :span="4" ><img src="../assets/x-circle.jpg" style="max-width: 80%;height: auto;"/></el-col>
          <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10" :span="10"></el-col>
        </el-row>
        <el-row>
          <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7"      :span="7"></el-col>
          <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10" :span="10"><p style="text-align: center;">续费失败，您可尝试刷新页面或者联系服协工作人员</p></el-col>
          <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7"      :span="7"></el-col>
        </el-row>
        <el-row>
          <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7"      :span="7"></el-col>
          <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10" :span="10"><p style="text-align: left;font-size: xx-small;">错误信息：{{subErrorMsg}}</p></el-col>
          <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7"      :span="7"></el-col>
        </el-row>
        <el-row>
          <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7"      :span="7"></el-col>
          <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10" :span="10"><p style="text-align: left;font-size: xx-small;">联系电话：020-8397331</p></el-col>
          <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7"      :span="7"></el-col>
        </el-row>
      </div>
    </el-dialog>

<!--入会申请提交后dialog-->
<el-dialog
      v-model="renewDialog"
      title=""
      :width="dialogWidth"
      :before-close="renewDialogClose"
    >
      <div>
        <el-row>
          <el-col :span="4" ></el-col>
          <el-col :span="16"><p style="font-weight:600;text-align: center;">续费信息确认</p></el-col>
          <el-col :span="4" ></el-col>
        </el-row>
        <el-row>
          <el-col :span="4" ></el-col>
          <!-- <el-col :span="14"><p style="text-align: left;">公司名：<span style="font-weight:600">{{form.corporate_name}}</span></p></el-col> -->
          <el-col :span="16"><p style="text-align: left;font-weight:600;">公司名：</p><span style="display:block">{{form.corporate_name}}</span></el-col>
          <el-col :span="4" ></el-col>
        </el-row>
        <el-row>
          <el-col :span="4" ></el-col>
          <!-- <el-col :span="16"><p style="text-align: left;">公司地址：<span style="font-weight:600">{{form.company_address}}</span></p></el-col> -->
          <el-col :span="16"><p style="text-align: left;font-weight:600;">公司地址：</p><span style="display:block">{{form.company_address}}</span></el-col>
          <el-col :span="4" ></el-col>
        </el-row>
        <el-row>
          <el-col :span="4" ></el-col>
          <!-- <el-col :span="16"><p style="text-align: left;">申请时所填手机号码：<span style="font-weight:600">{{form.mobile}}</span></p></el-col> -->
          <el-col :span="16"><p style="text-align: left;font-weight:600;">申请时所填手机号码：</p><span style="display:block">{{form.mobile}}</span></el-col>
          <el-col :span="4" ></el-col>
        </el-row>
      </div>
    
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="renewConfirm">
            确定
          </el-button>
          <el-button type="default" @click="renewCancel">
            取消
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>

// let wx = window.wx;

import wx from 'weixin-jsapi'
import axios from 'axios';
import sha1 from 'js-sha1';
import { useRoute } from 'vue-router';
export default {
    name:'Layout',
    data () {
        return {
          //表单
          form:{
            name: '',//姓名
            position:'',//职位
            radio:'会员单位',//入会类型
            mobile:'',//手机
            email:'',//邮箱
            corporate_name:'',//公司名称
            company_address:'',//公司地址
            msg:'',//留言
          },
          //校验规则
          rules: {
            name: [
              { required: true, message: '姓名不能为空', trigger: 'blur' },
              { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
            ],
            mobile:[
              { validator:this.validatorMobile, trigger: 'blur'},
            ],
            corporate_name:[
              { required: true, message: '公司名称不能为空', trigger: 'blur' },
            ]
          },
          //按钮禁用
          application_btn:false,
          //提交后弹框
          dialogVisible:false,
          //响应式弹框宽度
          dialogWidth:0,
          //抽屉显示
          drawer:false,
          //抽屉方向
          direction:'ltr',
          //表单提交loading...
          loading:false,
          //选择加载loading
          loadingSelect:false,
          //判断申请结果状态
          subStatus:false,
          //申请失败的错误信息
          subErrorMsg:'',
          //微信js-api-token
          token:"72_INA9C5VaAupGN-asXNhKhAv1JdOa2NEUjLPxBDHBHTSiFbbJj1VRVUnNvgpXnIZP6jMGZz4aZPWt2wETfDjvCgoO70XifwbgypUDNFefQl8bh01EzDcDPwEP8LAQQIaADAFFX",
          //微信js-api-ticket
          ticket:'O3SMpm8bG7kJnF36aXbe8yyqrylnh2AduvKyj3fiRhnNAOF-1uZGCE_90ju126CW8AvjGI21gtu4OQqh9UTugQ',
          //企业id
          corpid: 'wwc61024303f3b5649',
          //企业secret
          secret: 'gUu-gYwmedX3EqzE2gHv_uSz1y5SVa0wgXYB6ZA8-wk',
          //会员人员资料ID
          accountId:'',
          //跟进人
          follow_up_person:'',
          //负责人
          head:'',
          //推荐人
          reference:'',
          //来源
          source:'',
          //行记录ID
          rowId:'',
          //二维码id
          config_id:'',
          //微信授权码
          code:'',
          //微信支付的手机号码
          state:'',
          //选择列表
          options:[],
          //openId
          openid:'',
          //可续费
          renewable:false,
          //续费弹框控制
          renewDialog:false,
          //续费结束弹框控制
          renewEndDialog:false,
          //手机号固定
          iphone_number:'',
          //支付链接
          payUrl:'',
          //支付确认
          payConfig:true,
        }
    },

    methods:{
      //续费
      renew(){
        //弹出企业信息框，确认后可续费
        this.renewDialog = true;
      },
      //续费确认
      async renewConfirm(){
        this.renewDialog = false;
        await this.getRenewPayChatId(this.openid,this.iphone_number);
      },
      //续费取消
      renewCancel(){
        this.renewDialog = false;
      },
      //表单提交
      onSubmit(){
        var that = this;
        this.$refs['form'].validate((valid,fields) => {
          if (valid) {
            that.loading = true;
            that.application_btn = true;
            axios.post('/mdy/v2/open/worksheet/addRow',
            {
              "appKey": "01f242851a8cd3f5",
              "sign": "MmY5MDgxNTc1YTQ0YjA4NjgwZTJhODQzZGVkYWQ3ZThhZGE2NjdkMjVkNzU1OTgzMGFjMDFiMzllNjRmODI1YQ==",
              "worksheetId": "6528f1fce85e22627f91d638",
              "controls": [
                {
                  //申请状态
                  "controlId": "zhuangtai",
                  "value": "待处理",
                  "valueType": "1"
                },
                {
                  //入会类型
                  "controlId": "rhlx",
                  "value": that.form.radio,
                  "valueType": "1"
                },
                {
                  //来源
                  "controlId": "laiyuan",
                  "value": that.source,
                  "valueType": "1"
                },
                {
                  //姓名
                  "controlId": "xingming",
                  "value": that.form.name
                },
                {
                  //负责人
                  "controlId": "hygw",
                  "value": that.head
                },
                {
                  //跟进人
                  "controlId": "652f7d3a8c71301c58a2d8de",
                  "value": that.follow_up_person
                },
                {
                  //推荐人
                  "controlId": "652768736e1d7fdd465e5162",
                  "value": that.accountId
                },
                {
                  //付款状态
                  "controlId": "652e04c4b2073276dba0a93d",
                  "value": '未付款',
                  "valueType": "1"
                },
                {
                  //手机号码
                  "controlId": "shouji",
                  "value": that.form.mobile
                },
                {
                  //公司名
                  "controlId": "652dee128c71301c58a0c39d",
                  "value": that.form.corporate_name
                },
                {
                  //留言
                  "controlId": "64ddec1fed4b09e16066ba21",
                  "value": that.form.msg
                },{
                  //邮箱
                  "controlId": "65308b2ab2073276dba35458",
                  "value": that.form.email
                },{
                  //职位
                  "controlId": "65308b2ab2073276dba35457",
                  "value": that.form.position
                },{
                  //地址
                  "controlId": "65308b3e187a94264865a0a6",
                  "value": that.form.company_address
                },{
                  //openId
                  "controlId": "6567076ec252a8286bdf1f5b",
                  "value": that.openid
                },
              ],
              "triggerWorkflow": true
            }).then(async function(res){
              //明道云新增后的行记录id
              that.rowId = res.data.data;
              
              //调起微信支付
              // that.wxpayment();
              //获取预支付会话id
              that.state = that.iphone_number = that.form.mobile;
              // that.getPayChatId(that.code,that.form.mobile);
              that.getPayChatId(that.openid,that.form.mobile);
              that.subStatus = true;
              that.dialogVisible = true;
              
            }).catch(error => {
              setTimeout(()=>{
                that.loading = false;
                that.resetForm();
                that.subStatus = false;
                that.subErrorMsg = error;
                that.dialogVisible = true;
                that.application_btn = false;
              },1500);
            });
          } else {
            return false;
          }
        });
      },
      //选择项
      remoteMethod(queryString){
        var that = this;
        if (queryString !== '') {
          that.loadingSelect = true
          setTimeout(() => {
            that.loadingSelect = false
            axios.get('/tianyancha/services/open/search/2.0?word='+queryString+'&pageSize=20&pageNum=1',{
              headers: {
                'Authorization': '08d341f3-c960-44a0-9342-e614c68c3c1b'
              }
            }).then(async function(data){
              if(data.data.error_code==0){
                that.options = [];
                var arr = data.data.result.items;
                arr.forEach(function(e){
                  var obj = {value: e.name,label:e.name};
                  that.options.push(obj);
                });
              }
            });
          }, 200)
        } else {
          that.options = []
        }
      },
      //选择公司名称项
      handleSelect(item){
        this.corporate_name = item.value;
      },
      //导航栏显示控制
      head_ip_click(){
        this.drawer = !this.drawer;
      },
      //表单重置
      resetForm() {
        this.$refs['form'].resetFields();
      },
      //关闭提示框
      dialogClose(){
        // this.dialogVisible = false;
        window.location.href = 'https://application.sgia.org.cn';
      },
      //关闭续费提示框
      renewDialogClose(){
        this.renewDialog = false;
      },
      //关闭续费完成后提示框
      renewEndDialogClose(){
        this.renewEndDialog = false;
      },
      //跳转首页
      turnToIndex(){
        this.resetForm();
        this.dialogClose();
        window.location.href="https://www.sgia.org.cn/" ;
      },
      //跳转时装周
      turnToSzz(){
        this.resetForm();
        this.dialogClose();
        window.location.href="https://8397kvn28511l.cave.functorz.com/" ;
      },
      //跳转协会介绍
      turnToXhjs(){
        this.resetForm();
        this.dialogClose();
        window.location.href="https://www.sgia.org.cn/lkqjb25c" ;
      },
      //跳转会员申请
      turnToHysq(){
        this.resetForm();
        this.dialogClose();
        window.location.href="https://www.sgia.org.cn/lkqjbvrm" ;
      },
      //跳转信息公开
      turnToXxgk(){
        this.resetForm();
        this.dialogClose();
        window.location.href="https://www.sgia.org.cn/lkqjclba" ;
      },
      //跳转党建引领
      turnToDjyl(){
        this.resetForm();
        this.dialogClose();
        window.location.href="https://www.sgia.org.cn/lkqjeeej" ;
      },
      //跳转知识产权保护专区
      turnToZscqbhzq(){
        this.resetForm();
        this.dialogClose();
        window.location.href="https://www.sgia.org.cn/lkqjervq" ;
      },
      //取消（再填写一份）
      cancel(){
        // this.resetForm();
        // this.dialogClose();
        window.location.href = 'https://application.sgia.org.cn';
      },
      //手机校验
      validatorMobile(rule,value,callback){
        if(value === ''){
          callback(new Error('手机号不能为空'));
        }else if(!this.regMobile(value)){
          callback(new Error('请填写正确手机号码格式'));
        }else{
          callback();
        }
      },
      //手机校验
      regMobile(mobile){
        var reg = /^1[3456789]\d{9}$/;
        // ^1  以1开头
        // [3456789] 第2位，使用原子表里的任意一个原子都可以
        // \d{9}$  第三位  朝后可以是任意数字  并且最后结尾必须是数字
        if(reg.test(mobile)){
          return true;
        }else{
          return false;
        }
      },
      //设置宽度
      setDialogWidth() {
        var val = document.body.clientWidth
        const def = 800 // 默认宽度
        if (val < def) {
          this.dialogWidth = '80%'
        } else {
          this.dialogWidth = 40 + '%'
        }
      },
      //获取时间戳
      getTimestamp() {
        return Date.parse(new Date());
      },
      //随机字符串
      randomString(len){
        len = len || 32
        var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
        var maxPos = $chars.length
        var pwd = ''
        for (var i = 0; i < len; i++) {
          pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
        }
        return pwd;
      },
      //获取签名
      getSignature(ticket,noncestr,timestamp,url) {
        let jsapi_ticket = `jsapi_ticket=${ticket}&noncestr=${noncestr}&timestamp=${timestamp}&url=${url}`
        return sha1(jsapi_ticket);
      },
      //获取accessToken
      accessToken(){
        var that = this;
        axios.get('/wxapi/cgi-bin/token?grant_type=client_credential&appid=wxd319ed66e5318016&secret=f08bbe56e70cf7116ea198b8ca248797').then(function(res){
          that.token = res.data.access_token;
          return res.data.access_token;
        });
      },
      //获取ticket
      getTicket(){
        var that = this;
        axios.get('/wxapi/cgi-bin/ticket/getticket?access_token='+that.token+'&type=jsapi').then(function(res){
          that.token = res.data.access_token;
          if(res.data.errcode==40001){
            that.accessToken();
            axios.get('/wxapi/cgi-bin/ticket/getticket?access_token='+that.token+'&type=jsapi').then(function(res){
              that.ticket = res.data.ticket;
              return res.data.ticket;
            });
          }
          return res.data.ticket;
        });
      },
      //获取续费预支付会话id
      async getRenewPayChatId(openid,iphone_number){
        // alert(iphone_number);
        var that = this;
        axios.get('/pay-api/renew/'+openid+'/'+iphone_number).then(async function(res){
          var a = res['data'];
          var b = a['data'];
          await that.wxRenewCallback(b);
        }).catch(error=>{
          alert('抱歉，系统发生错误，请截图联系工作人员，错误信息：'+error);
        })
      },
      //获取预支付会话id
      getPayChatId(openid,mobile){
        var that = this;
        axios.get('/pay-api/getToken/'+openid+'/'+mobile).then(async function(res){
          var a = res['data'];
          var b = a['data'];
          await that.wxCallback(b);
        }).catch(error=>{
          alert('抱歉，系统发生错误，请截图联系工作人员，错误信息：'+error);
        })
      },
      //检测微信插件是否准备完成
      async wxCallback(options){
        let that = this;
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(options), false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(options));
            document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(options));
          }
        } else {
          await that.onBridgeReady(options);
        }
      },
      ///入会支付通知
      async onBridgeReady(options){
        let that = this;
        that.loading = false;
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
          "appId": options['appId'],     //公众号ID，由商户传入     
          "timeStamp": options['timeStamp'],     //时间戳，自1970年以来的秒数     
          "nonceStr": options['nonceStr'],      //随机串     
          "package": options['package'],
          "signType": options['RSA'],     //微信签名方式：     
          "paySign": options['paySign']
        },
        async function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付成功');
              //修改订单状态
              await that.updateOrderState("支付成功");
              that.loading = false;
              that.application_btn = false;
          }
          if (res.err_msg == "get_brand_wcpay_request:cancel") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付取消');
              
              //修改订单状态
              await that.updateOrderState("未支付");
              that.loading = false;
              that.application_btn = false;
          }
          if (res.err_msg == "get_brand_wcpay_request:fail") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付失败');
              //修改订单状态
              await that.updateOrderState("未支付");
              that.loading = false;
              that.application_btn = false;
          }
        });
      },
      //检测微信插件是否准备完成
      async wxRenewCallback(options){
        let that = this;
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', that.renewOnBridgeReady(options), false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', that.renewOnBridgeReady(options));
            document.attachEvent('onWeixinJSBridgeReady', that.renewOnBridgeReady(options));
          }
        } else {
          await that.renewOnBridgeReady(options);
        }
      },
      ///支付通知
      async renewOnBridgeReady(options){
        let that = this;
        that.loading = false;
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
          "appId": options['appId'],     //公众号ID，由商户传入     
          "timeStamp": options['timeStamp'],     //时间戳，自1970年以来的秒数     
          "nonceStr": options['nonceStr'],      //随机串     
          "package": options['package'],
          "signType": options['RSA'],     //微信签名方式：     
          "paySign": options['paySign']
        },
        async function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付成功');
              //修改订单状态
              await that.updateOrderState("支付成功");
              that.loading = false;
              that.application_btn = false;
          }
          if (res.err_msg == "get_brand_wcpay_request:cancel") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付取消');
              
              //修改订单状态
              await that.updateOrderState("未支付");
              that.loading = false;
              that.application_btn = false;
          }
          if (res.err_msg == "get_brand_wcpay_request:fail") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付失败');
              //修改订单状态
              await that.updateOrderState("未支付");
              that.loading = false;
              that.application_btn = false;
          }
        });
      },
      //初始化微信插件
      initWxConfig(){
        let that = this;
        that.getTicket();
        var nonceStr = that.randomString(32);
        var timestamp = that.getTimestamp();
        var url = 'https://application.sgia.org.cn/';
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxd319ed66e5318016', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: that.getSignature(that.ticket,nonceStr,timestamp,url),// 必填，签名
          jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function(){
          wx.checkJsApi({
            jsApiList: ['chooseWXPay'],
            success:function(res){
            },
            fail:function(res){
            }
          })
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        });
        wx.error(function(res){
          alert('微信插件加载失败：'+res);
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });

      },
      // 调用支付
      wxpayment(){
        var mobile = this.form.mobile;
        // if(typeof(this.code) == "undefined" || this.code == '' || this.code == null){
        var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd319ed66e5318016&redirect_uri="+encodeURI("https://application.sgia.org.cn")+"&response_type=code&scope=snsapi_base&state="+mobile+"#wechat_redirect";
        window.location.href = url;
        // }
      },
      //修改订单状态
      async getOrderRowId(result){
        var that = this;
        var mobile = that.iphone_number;
        //根据手机号获取订单信息，把当前的支付通知返回给明道云
        await axios.post('/mdy/v2/open/worksheet/getFilterRows',
        {
          "appKey": "01f242851a8cd3f5",
          "sign": "MmY5MDgxNTc1YTQ0YjA4NjgwZTJhODQzZGVkYWQ3ZThhZGE2NjdkMjVkNzU1OTgzMGFjMDFiMzllNjRmODI1YQ==",
          "worksheetId": "dingdan",
          "viewId": "",
          "pageSize": 50,
          "pageIndex": 1,
          "filters": [
            {
              "controlId": "6506851d533dc247c621d529",
              "dataType": 3,
              "spliceType": 1,
              "filterType": 2,
              "value": mobile
            }
          ]
        },{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(async function(res){
          //明道云新增后的行记录id
          var a = await res.data.data.rows[0];
          var rowId =await a['rowid'];
          await that.updateOderStateByRowId(rowId,result);
        }).catch(function(error){
          alert('修改订单状态错误:'+error);
        });
      },
      //修改订单状态
      async updateOrderState(result){
        var that = this;
        await that.getOrderRowId(result);
      },
      //根据行id修改订单状态
      async updateOderStateByRowId(rowId,result){
        let that = this;
        await axios.post('/mdy/v2/open/worksheet/editRow',
        {
          "appKey": "01f242851a8cd3f5",
          "sign": "MmY5MDgxNTc1YTQ0YjA4NjgwZTJhODQzZGVkYWQ3ZThhZGE2NjdkMjVkNzU1OTgzMGFjMDFiMzllNjRmODI1YQ==",
          "worksheetId": "dingdan",
          "rowId":rowId,
          "controls": [
            {
              "controlId": "64fe7bbc8255b422ee951939",
              "value": result,
              "valueType": 1
            },{
              "controlId": "65292460c560948027d12424",
              "value": that.rowId,
            }
          ],
          "triggerWorkflow": true
        },{
          headers: {
            'Content-Type': 'application/json'
          }
        }).catch(function(error){
          alert(error);
        });
      },
      //根据accountId获取负责人和更近人的Id
      getPeople(){
        var that = this;
        const appKey = '01f242851a8cd3f5';
        const sign = 'MmY5MDgxNTc1YTQ0YjA4NjgwZTJhODQzZGVkYWQ3ZThhZGE2NjdkMjVkNzU1OTgzMGFjMDFiMzllNjRmODI1YQ==';
        const worksheetId = '6221eee9c373e5dab6152a13';
        var rowId = that.accountId;
        const getSystemControl = false;
        axios.get(`/mdy/v2/open/worksheet/getRowById?appKey=${appKey}&sign=${sign}&worksheetId=${worksheetId}&rowId=${rowId}&getSystemControl=${getSystemControl}`,
        {},{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(function(res){
          var follow_up_person_obj =  res.data.data['652f51a33978291630302c26']
          var head_obj =  res.data.data['6226e6845e53f8b027662aff']
          if(follow_up_person_obj=="" || typeof(follow_up_person_obj)=="undefined" || follow_up_person_obj==null){
            that.follow_up_person = '';
          }else{
            that.follow_up_person = JSON.parse(follow_up_person_obj)[0].accountId;
          }
          if(head_obj=="" || typeof(head_obj)=="undefined" || head_obj==null){
            that.head_obj = '';
          }else{
            that.head = JSON.parse(head_obj)[0].accountId;
          }
        }).catch(function(error){
          alert(error);
        });
      },
      //根据code获取openId
      async getOpenIdByCode(){
        let that = this;
        axios.get('/wxapi/sns/oauth2/access_token?appid=wxd319ed66e5318016&secret=f08bbe56e70cf7116ea198b8ca248797&code='+this.code+'&grant_type=authorization_code')
        .then(async res=>{
          that.openid = await res.data.openid;
          // that.unionid = await res.data.unionid;
          // alert('unionid'+that.unionid);
          await that.queryIsHavingOpenId(that.openid);
          // var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd319ed66e5318016&redirect_uri="+encodeURI("https://application.sgia.org.cn")+"&response_type=code&scope=snsapi_base&state="+that.openid+"#wechat_redirect";
          // window.location.href = url;
        }).catch(error => {
          alert('抱歉，系统错误：'+error+';请截图联系工作人员处理！');
        });
      },
      //查询线索中是否存在该openId
      async queryIsHavingOpenId(openid){
        let that = this;
        axios.post('/mdy/v2/open/worksheet/getFilterRows',
        {
          "appKey": "01f242851a8cd3f5",
          "sign": "MmY5MDgxNTc1YTQ0YjA4NjgwZTJhODQzZGVkYWQ3ZThhZGE2NjdkMjVkNzU1OTgzMGFjMDFiMzllNjRmODI1YQ==",
          "worksheetId": "6528f1fce85e22627f91d638",
          "viewId": "",
          "pageSize": 50,
          "pageIndex": 1,
          "sortId":'ctime',
          "isAsc":false,
          "filters": [
            {
              "controlId": "6567076ec252a8286bdf1f5b",
              "dataType": 2,
              "spliceType": 1,
              "filterType": 2,
              "value": openid
            },{
              "controlId": "62998aa5e9319b9e521ee519",
              "dataType": 11,
              "spliceType": 1,
              "filterType": 2,
              "value": '新线索'
            }
          ]
        },{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(async function(res){
          var res_list = await res.data.data.rows;
          if(res_list.length>0){
            var obj = res_list[0];
            await that.queryDqTimeByOpenId(obj['652dee128c71301c58a0c39d']);
            //公司名
            that.form.corporate_name = obj['652dee128c71301c58a0c39d'];
            //姓名
            that.form.name = obj['xingming'];
            //职位
            that.form.position = obj['65308b2ab2073276dba35457'];
            //手机
            that.iphone_number = that.form.mobile = obj['shouji'].slice(3);
            //email
            that.form.email = obj['65308b2ab2073276dba35458'];
            //公司地址
            that.form.company_address = obj['65308b3e187a94264865a0a6'];
            //留言
            that.form.msg = obj['64ddec1fed4b09e16066ba21'];
            //申请单位
            that.form.radio = obj['rhlx'];
            //rowId
            that.rowId = obj['rowid'];
            //开启续费按钮
            // var status = obj['zhuangtai'];
            // if(status == '服务中' || status == '已付款' || status == '即将到期' || status == '已过期'){
            //   that.renewable = true;
            // }
          }
        }).catch(function(error){
          alert(error);
        });
      },
      //根据openid查询是否可续费
      async queryDqTimeByOpenId(gsmc){
        let that = this;
        axios.post('/mdy/v2/open/worksheet/getFilterRows',
        {
          "appKey": "01f242851a8cd3f5",
          "sign": "MmY5MDgxNTc1YTQ0YjA4NjgwZTJhODQzZGVkYWQ3ZThhZGE2NjdkMjVkNzU1OTgzMGFjMDFiMzllNjRmODI1YQ==",
          "worksheetId": "62998aa5e9319b9e521ee518",
          "viewId": "",
          "pageSize": 50,
          "pageIndex": 1,
          "sortId":"ctime",
          "isAsc":false,
          "filters": [
            {
              "controlId": "62998aa5e9319b9e521ee522",
              "dataType": 51,
              "spliceType": 1,
              "filterType": 2,
              "value": gsmc
            }
          ]
        },{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(async function(res){
          var res_list = await res.data.data.rows;
          if(res_list.length>0){
            var obj = res_list[0];
            //当前状态
            var status = obj['zhuangtai'];
            if(status == '服务中' || status == '已付款' || status == '即将到期' || status == '已过期'){
              that.renewable = true;
            }
          }
        }).catch(function(error){
          alert(error);
        });
      }
    },

    watch:{

    },

    created(){
      this.setDialogWidth();
    },

    async mounted(){
      //获取路由参数
      const route = useRoute();
      this.code = route.query.code;
      this.state = route.query.state;
      this.accountId = route.query.accountId;
      this.source = route.query.source;
      this.payUrl = route.query.payUrl;
      if(typeof(this.code) == "undefined"){
        //未授权
        var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd319ed66e5318016&redirect_uri="+encodeURI("https://application.sgia.org.cn")+"&response_type=code&scope=snsapi_base&state="+this.accountId+"#wechat_redirect";
        // var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd319ed66e5318016&redirect_uri="+encodeURI("https://application.sgia.org.cn/sfx?type=signature&activity_registration_id=9")+"&response_type=code&scope=snsapi_base&state="+this.accountId+"#wechat_redirect";
        // var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd319ed66e5318016&redirect_uri="+encodeURI("https://application.sgia.org.cn")+"&response_type=code&scope=snsapi_userinfo&state="+this.accountId+"#wechat_redirect";
        window.location.href = url;
      }else{
        this.getOpenIdByCode(this.code);
      }
      //初始化微信插件
      this.initWxConfig();
      window.onresize = () => {
        return (() => {
          this.setDialogWidth()
        })()
      };
      if(typeof(this.accountId) == "undefined"){
        this.accountId = '37e4a173-a652-4ba1-985b-a600fe3fcc65';
      }
      if(typeof(this.source) == "undefined"){
        this.source = '深服协官网';
      }
      this.getPeople();
    }
}
</script>

<style scoped>
  .required{
    color: red;
  }

  .hidden-xs-only > div > ul > li{
    display: inline-block;
    height: auto;
    line-height: auto;
    margin-left:4%;
    font-size: large;
    
  }

  .hidden-xs-only > div > ul > li:hover{
    cursor: pointer;
  }

  .action_pc{
    
    font-size: larger;
    font-weight: bold;
    font: #000000;
  }

  .ip_ul > li{
    width: 100%;
    height: auto;
    line-height: auto;
    font-size: 14px;
    font: #000000;
    opacity: 0.95;
    margin-top: 15%;
  }
  
  .ip_ul > li:hover{
    cursor: pointer;
  }

  .ip_ul > .action{
    font-size: 14px;
    font-weight: 600;
    opacity: 1;
  }

  .sub_span:hover{
    cursor: pointer;
    color: rgb(38, 125, 230) ;
    box-shadow: 0px 1px 3px 3px rgb(38, 125, 230);
  }

  .ip_sub_btn:hover{
    cursor: pointer;
  }

  .head_ip:hover{
    cursor: pointer;
  }

  .el-form_ip{
    box-shadow: 0px 2px 3px 3px #f8f9fb;
  }
</style>